import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Box, LinearProgress, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Container, Paper, ThemeProvider } from '@mui/material';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { theme, colors } from '../Colors';
import axios from 'axios';

const StyledTableCell = styled(TableCell)(() => ({
    backgroundColor: colors.tableHeadBackground,
    color: colors.tableHeadText
}));

function PatientInfoDisplay() {
    //const baseUrl = 'http://localhost:4000';
    const baseUrl = 'https://flairhealthbackend.onrender.com';

    const { id: patientId } = useParams();
    const [patientInfo, setPatientInfo] = useState([]);
    const [careLogInfo, setCareLogInfo] = useState([]);

    // useEffect(() => {
    //     if (!patientId) {
    //         console.log("Error: patientId is undefined or empty");
    //         return;
    //     }
    //     Promise.all([
    //         axios.get(`${baseUrl}/patients/${patientId}/`),
    //         axios.get(`${baseUrl}/patients/careLogs/${patientId}/`)
    //     ])
    //     .then(([patientResponse, careLogsResponse]) => {
    //         setPatientInfo(patientResponse.data);
    //         setCareLogInfo(careLogsResponse.data);
    //     })
    //     .catch(err => {
    //         console.log("Error: " + err);
    //     });
    // }, [patientId, baseUrl]);

    // useEffect(() => {
    //     axios.get(`${baseUrl}/patients/${patientId}/`)
    //     .then(response => {
    //         setPatientInfo(response.data);
    //     })
    //     .catch(err => {
    //         console.log("Error: " + err);
    //     });
    // }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/patients/${patientId}/`);
                setPatientInfo(response.data);
            } catch (error) {
                console.log("Error: " + error);
            }
        };
        fetchData();
    }, []);

    const DisplayProgramPathways = (patient) => {
        const selectedPathways = [];
        if (patient.intakeInfo.dieticianPathway === true) {
          selectedPathways.push("Dietician and Nutrition Counseling");
        }
        if (patient.intakeInfo.medAdherencePathway === true) {
          selectedPathways.push("Medication Adherence");
        }
        if (patient.intakeInfo.patientEducationPathway === true) {
          selectedPathways.push("Health Education and Coaching");
        }
        return selectedPathways.join(', ');
    };

    return (
        <div>
            <Container sx = {{ paddingBottom: '2%' }}>
                <Typography variant="h5" style={{ textAlign: 'left', paddingBottom: '10px', marginBottom: '10px' }} color={colors.tableHeadText}>
                    {patientInfo.firstName} {patientInfo.lastName}'s Patient Profile 
                </Typography>
                <Paper elevation={1} style={{ padding: '20px' }}>
                    <Typography variant="h6" style={{ textAlign: 'left', paddingBottom: '10px', marginBottom: '5px' }} color={colors.tableHeadText}>
                        Information
                    </Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}>
                        Birth Date: {new Date(patientInfo.birthDate).toLocaleDateString()}</Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}>
                        Sex: {patientInfo.sex}</Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}>
                        State: {patientInfo.state}</Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}>
                        Email: {patientInfo.email}</Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}>
                        Phone: {patientInfo.phone}</Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}>
                        Insurance: {patientInfo.insuranceProvider}</Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}>
                        Clinic Name: {patientInfo.intakeInfo.clinicName}</Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}>
                        Pathways: {DisplayProgramPathways(patientInfo)}</Typography>
                    <Typography style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}>
                        Referral Notes: {patientInfo.intakeInfo.referralNotes}</Typography>
                </Paper>
                    
                {/* <TableContainer component={Paper}>
                    <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                        <StyledTableCell><Typography variant="body1">Goal Description</Typography></StyledTableCell>
                        <StyledTableCell><Typography variant="body1">Frequency</Typography></StyledTableCell>
                        <StyledTableCell><Typography variant="body1">Notification Time</Typography></StyledTableCell>
                        <StyledTableCell><Typography variant="body1">End Date</Typography></StyledTableCell>
                        <StyledTableCell><Typography variant="body1">Goal Progress</Typography></StyledTableCell>
                        <StyledTableCell><Typography variant="body1">Edit Goal</Typography></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    </Table>
                </TableContainer> */}
            </Container>

            {/* <Container sx = {{ paddingBottom: '2%' }}>
                <Typography variant="h5" style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}> Patient Chart </Typography>
                
                <Accordion style={{ marginBottom: '15px' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography variant="h6" color={colors.tableHeadText}>Nutrition <span style={{ textAlign: 'left', fontStyle: 'italic' }}>(data from MyFitnessPal integration)</span></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <hr style={{ color: 'black', marginTop: '-10px', marginBottom: '5px' }} />
                        <ThemeProvider theme={theme}> <Button variant='contained' sx={{ marginLeft: '85%', marginTop: '10px' }} onClick={handleOpenFoodDialog}>Food Tracking</Button> </ThemeProvider>
                        <Dialog open={openFoodDialog} onClose={handleCloseFoodDialog}>
                        <DialogTitle>
                        <Typography variant="h5" style={{ marginTop: '10px' }} color={colors.tableHeadText}>Food Tracking</Typography>
                        </DialogTitle>
                        <DialogContent> {addFoodInfo()} </DialogContent>
                        <DialogActions sx={{ marginBottom: '10px' }}>
                        <Button onClick={handleCloseFoodDialog} color="primary">Cancel</Button>
                        <Button onClick={handleAddGoal} color="primary">Add Goal</Button>
                        </DialogActions>
                        </Dialog>
                    </AccordionDetails>
                </Accordion>
                
                <Accordion style={{ marginBottom: '15px' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" color={colors.tableHeadText}>Exercise <span style={{ textAlign: 'left', fontStyle: 'italic' }}>(data from Apple Health integration)</span> </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <hr style={{ color: 'black', marginTop: '-10px', marginBottom: '5px' }} />
                    </AccordionDetails>
                </Accordion>

                <Accordion style={{ marginBottom: '15px' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" color={colors.tableHeadText}>Sleep <span style={{ textAlign: 'left', fontStyle: 'italic' }}>(data from Apple Health integration)</span> </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <hr style={{ color: 'black', marginTop: '-10px', marginBottom: '5px' }} />
                    </AccordionDetails>
                </Accordion>

                <Accordion style={{ marginBottom: '15px' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h6" color={colors.tableHeadText}>Stress </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <hr style={{ color: 'black', marginTop: '-10px', marginBottom: '5px' }} />
                    </AccordionDetails>
                </Accordion>

                <Accordion style={{ marginBottom: '15px' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                        <Typography variant="h6" color={colors.tableHeadText}>Lab Results</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <hr style={{ color: 'black', marginTop: '-10px', marginBottom: '5px' }} />
                        <TableContainer component={Paper} sx={{ width: '100%' }}>
                        <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell><Typography variant="body1">Lab Name</Typography></StyledTableCell>
                                <StyledTableCell><Typography variant="body1">Result</Typography></StyledTableCell>
                                <StyledTableCell><Typography variant="body1">In Range</Typography></StyledTableCell>
                                <StyledTableCell><Typography variant="body1">Date</Typography></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Fasting Blood Glucose</TableCell>
                                <TableCell>119 mg/dL</TableCell>
                                <TableCell><span style={{ color: 'green' }}>YES</span></TableCell>
                                <TableCell>August 21, 2023</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>HbA1c</TableCell>
                                <TableCell>6.8%</TableCell>
                                <TableCell><span style={{ color: 'red' }}>NO</span></TableCell>
                                <TableCell>August 21, 2023</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Fasting Blood Glucose</TableCell>
                                <TableCell>129 mg/dL</TableCell>
                                <TableCell><span style={{ color: 'red' }}>NO</span></TableCell>
                                <TableCell>June 19, 2023</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>HbA1c</TableCell>
                                <TableCell>6.9%</TableCell>
                                <TableCell><span style={{ color: 'red' }}>NO</span></TableCell>
                                <TableCell>June 19, 2023</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Fasting Blood Glucose</TableCell>
                                <TableCell>133 mg/dL</TableCell>
                                <TableCell><span style={{ color: 'red' }}>NO</span></TableCell>
                                <TableCell>March 29, 2023</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>HbA1c</TableCell>
                                <TableCell>7.0%</TableCell>
                                <TableCell><span style={{ color: 'red' }}>NO</span></TableCell>
                                <TableCell>March 29, 2023</TableCell>
                            </TableRow>
                        </TableBody>
                        </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>

                <Accordion style={{ marginBottom: '15px' }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6" color={colors.tableHeadText}>Medications</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <hr style={{ color: 'black', marginTop: '-10px', marginBottom: '5px' }} />
                        <TableContainer component={Paper} sx={{ width: '100%' }}>
                        <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell><Typography variant="body1">Medication Name</Typography></StyledTableCell>
                                <StyledTableCell><Typography variant="body1">Dosage</Typography></StyledTableCell>
                                <StyledTableCell><Typography variant="body1">Date</Typography></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Metformin</TableCell><TableCell>500mg</TableCell><TableCell>August 21, 2023</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Metformin</TableCell><TableCell>1500mg</TableCell><TableCell>June 19, 2023</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Pioglitazone</TableCell><TableCell>7.5mg</TableCell><TableCell>June 19, 2023</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Metformin</TableCell><TableCell>2000mg</TableCell><TableCell>March 29, 2023</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Pioglitazone</TableCell><TableCell>30mg</TableCell><TableCell>March 29, 2023</TableCell>
                            </TableRow>
                        </TableBody>
                        </Table>
                        </TableContainer>
                    </AccordionDetails>
                </Accordion>
            </Container> */}
        </div>
    )
}

export default function OnboardPatient() {
    return (
        <div style={{ display: 'flex' }}>
        <Navbar />
        <Sidebar />
        <div className="App">
          <Container sx = {{ paddingBottom: '2%'}}>
            <ThemeProvider theme={theme}>
                <PatientInfoDisplay/>
            </ThemeProvider>
          </Container>
        </div>
      </div>
    )
}