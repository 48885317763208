import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../images/flair_logo.png';
import { Drawer, Button, IconButton } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { colors } from '../Colors';

const Sidebar = () => {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div>
        <IconButton onClick={toggleDrawer} sx={{ display: { xs: 'block', sm: 'none' } }}>
            <Menu />
        </IconButton>

        <Drawer variant="persistent" open={open}
          sx={{ width: 240, flexShrink: 0, backgroundColor: colors.navBarbackground,
            display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', zIndex: 1100,
            '& .MuiDrawer-paper': {
              width: 240, backgroundColor: colors.navBarbackground, zIndex: 1100,
              display: 'flex', flexDirection: 'column', border: 'none', alignItems: 'center',
            }
          }}
        >
          
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
          <a href="/patients" style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
            <img style={{ maxWidth: '70%', maxHeight: '70%', width: 'auto', height: 'auto', marginTop: '4%', marginBottom: '10%' }} src={logo} alt="Logo" />
          </a>

          <Button sx= {{color: 'white', fontSize: 14}} onClick={() => navigate(`/onboard`)}>Onboard</Button>
          <Button sx= {{color: 'white', fontSize: 14}} onClick={() => navigate(`/staff`)}>Staff</Button>
          <Button sx= {{color: 'white', fontSize: 14}} onClick={() => navigate(`/patients`)}>Patients</Button>
          <Button sx= {{color: 'white', fontSize: 14,}} onClick={() => navigate(`/billing`)}>Billing</Button>
        </div>
        </Drawer>
    </div>
  );
};

export default Sidebar;