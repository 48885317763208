import { createTheme } from '@mui/material/styles';
import { blue, indigo } from '@mui/material/colors';

export const theme = createTheme({
    palette: {
        primary: {
            main: indigo[800]
        },
        secondary: { 
            main: blue[800]
        },
        light: {
            main: indigo[50]
        }
    }
});

export const colors = {
    navBarbackground: '#12a9e1',
    tableHeadBackground: '#f6fafd',
    tableHeadText: '#1565c0'
}