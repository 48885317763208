import logo from '../images/flair_logo.png';

export default function Navbar() {
    return (
        <div className="header" style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}> 
            <img style={{ height: '100%' }} src={logo} alt="Logo" />
            <div style={{ display: 'flex' }}> 
            </div>
        </div>
    )
}