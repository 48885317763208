import React from 'react';
import { Box, Grid, Radio, RadioGroup, FormControl, FormControlLabel, FormHelperText, FormLabel} from '@mui/material';
import { InputLabel, Select, MenuItem, Container, Button, ThemeProvider, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { theme } from '../Colors';
import axios from 'axios';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

// const baseUrl = 'http://localhost:4000';
const baseUrl = 'https://flairhealthbackend.onrender.com';

function RevenueCalcForm() {

    const [state, setState] = useState('');
    const [isStateError, setStateError] = useState(false);
    const [stateHelperText, setStateHelperText] = useState('');

    const [payer, setPayer] = useState('');
    const [isPayerError, setPayerError] = useState(false);
    const [payerHelperText, setPayerHelperText] = useState('');

    const [numPatients, setNumPatients] = useState(0);
    const [isNumPatientsError, setNumPatientsError] = useState(false);
    const [numPatientsHelperText, setNumPatientsHelperText] = useState('');

    const [code, setCode] = useState('');
    const [isCodesError, setCodesError] = useState(false);
    const [codesHelperText, setCodesHelperText] = useState('');

    const [revenue, setRevenue] = useState(null);
    const [isRevenueError, setRevenueError] = useState(false);
    const [revenueHelperText, setRevenueHelperText] = useState('');

    const handleStateChange = (event) => {
        setState(event.target.value);
    };

    const handlePayerChange = (event) => {
        setPayer(event.target.value);
    };
    
    const handleNumPatientsChange = (event) => {
        setNumPatients(event.target.value);
    };
    
    const handleCodeChange = (event) => {
        setCode(event.target.value);
    };
    
    const handleSubmit = async() => {
        try {
            var valid = true;
            if (state === null || state === '') {
                setStateError(true);
                setStateHelperText('Please enter a valid state.');
                valid = false;
            } else {
                setStateError(false);
                setStateHelperText('');
                valid = true;
            }

            if (numPatients === null || state === '') {
                setNumPatientsError(true);
                setNumPatientsHelperText('Please enter a valid number.');
                valid = false;
            } else {
                setNumPatientsError(false);
                setNumPatientsHelperText('');
                valid = true;
            }

            if (code === null || code === '') {
                setCodesError(true);
                setCodesHelperText('Please select at least one Chronic Care Management code.');
                valid = false;
            } else {
                setCodesError(false);
                setCodesHelperText('');
                valid = true;
            }

            if (payer === null || payer === '') {
                setPayerError(true);
                setPayerHelperText('Please select a payer.');
                valid = false;
            } else {
                setPayerError(false);
                setPayerHelperText('');
                valid = true;
            }

            if (revenue === null) {
                setRevenue(0);
                setRevenueError(true);
                setRevenueHelperText('Unable to calculate revenue. Please recheck your inputs, or refresh the page and try again.');
                valid = false;
            }

            if (!valid) return;
            const response = await axios.get(`${baseUrl}/ccmcodes/reimbursement/${payer}/${state}/${code}`);
            console.log(response);
            if (response === null) {
                console.log("yesss");
            }
            var totalRevenue = Math.round(numPatients * response.data.reimbursement * 100) / 100;
            setRevenue(totalRevenue);

        } catch (err) { console.error('Error:', err) }
    };

    // Add informational text about CCM Codes
    // Commercial codes add the numbers

    return (
        <div>
            <Typography variant="h5" style={{ color: '#1565c0', marginTop: '45px', marginBottom: '10px' }}>Calculate Your Expected Revenue from Flair's Services</Typography>
            <div style={{ margin: '5px auto', width: '90%' }}>
                {/* State Input */}
                <div style={{ margin: '5px auto'}}>
                    <FormControl required sx={{ marginTop: '10px', width: '100%', textAlign: 'left' }} >
                    <InputLabel style={{ marginTop: '0px', color: isStateError ? '#c62828' : '666666' }}> State </InputLabel>
                    <Select required variant="outlined" name="state" value={state}
                        onChange={handleStateChange} error={isStateError} 
                        inputProps={{ style: { textAlign: 'left' } }} fullWidth >
                        <MenuItem value="Alabama" sx={{ textAlign: 'left' }}>Alabama</MenuItem>
                        <MenuItem value="Alaska" sx={{ textAlign: 'left' }}>Alaska</MenuItem>
                        <MenuItem value="Arizona" sx={{ textAlign: 'left' }}>Arizona</MenuItem>
                        <MenuItem value="Arkansas" sx={{ textAlign: 'left' }}>Arkansas</MenuItem>
                        <MenuItem value="California" sx={{ textAlign: 'left' }}>California</MenuItem>
                        <MenuItem value="Colorado" sx={{ textAlign: 'left' }}>Colorado</MenuItem>
                        <MenuItem value="Connecticut" sx={{ textAlign: 'left' }}>Connecticut</MenuItem>
                        <MenuItem value="D.C." sx={{ textAlign: 'left' }}>D.C.</MenuItem>
                        <MenuItem value="Delaware" sx={{ textAlign: 'left' }}>Delaware</MenuItem>
                        <MenuItem value="Florida" sx={{ textAlign: 'left' }}>Florida</MenuItem>
                        <MenuItem value="Georgia" sx={{ textAlign: 'left' }}>Georgia</MenuItem>
                        <MenuItem value="Hawaii" sx={{ textAlign: 'left' }}>Hawaii</MenuItem>
                        <MenuItem value="Idaho" sx={{ textAlign: 'left' }}>Idaho</MenuItem>
                        <MenuItem value="Illinois" sx={{ textAlign: 'left' }}>Illinois</MenuItem>
                        <MenuItem value="Indiana" sx={{ textAlign: 'left' }}>Indiana</MenuItem>
                        <MenuItem value="Iowa" sx={{ textAlign: 'left' }}>Iowa</MenuItem>
                        <MenuItem value="Kansas" sx={{ textAlign: 'left' }}>Kansas</MenuItem>
                        <MenuItem value="Kentucky" sx={{ textAlign: 'left' }}>Kentucky</MenuItem>
                        <MenuItem value="Louisiana" sx={{ textAlign: 'left' }}>Louisiana</MenuItem>
                        <MenuItem value="Maine" sx={{ textAlign: 'left' }}>Maine</MenuItem>
                        <MenuItem value="Maryland" sx={{ textAlign: 'left' }}>Maryland</MenuItem>
                        <MenuItem value="Massachusetts" sx={{ textAlign: 'left' }}>Massachusetts</MenuItem>
                        <MenuItem value="Michigan" sx={{ textAlign: 'left' }}>Michigan</MenuItem>
                        <MenuItem value="Minnesota" sx={{ textAlign: 'left' }}>Minnesota</MenuItem>
                        <MenuItem value="Mississippi" sx={{ textAlign: 'left' }}>Mississippi</MenuItem>
                        <MenuItem value="Missouri" sx={{ textAlign: 'left' }}>Missouri</MenuItem>
                        <MenuItem value="Montana" sx={{ textAlign: 'left' }}>Montana</MenuItem>
                        <MenuItem value="Nebraska" sx={{ textAlign: 'left' }}>Nebraska</MenuItem>
                        <MenuItem value="Nevada" sx={{ textAlign: 'left' }}>Nevada</MenuItem>
                        <MenuItem value="New Hampshire" sx={{ textAlign: 'left' }}>New Hampshire</MenuItem>
                        <MenuItem value="New Jersey" sx={{ textAlign: 'left' }}>New Jersey</MenuItem>
                        <MenuItem value="New Mexico" sx={{ textAlign: 'left' }}>New Mexico</MenuItem>
                        <MenuItem value="New York" sx={{ textAlign: 'left' }}>New York</MenuItem>
                        <MenuItem value="North Carolina" sx={{ textAlign: 'left' }}>North Carolina</MenuItem>
                        <MenuItem value="North Dakota" sx={{ textAlign: 'left' }}>North Dakota</MenuItem>
                        <MenuItem value="Ohio" sx={{ textAlign: 'left' }}>Ohio</MenuItem>
                        <MenuItem value="Oklahoma" sx={{ textAlign: 'left' }}>Oklahoma</MenuItem>
                        <MenuItem value="Oregon" sx={{ textAlign: 'left' }}>Oregon</MenuItem>
                        <MenuItem value="Pennsylvania" sx={{ textAlign: 'left' }}>Pennsylvania</MenuItem>
                        <MenuItem value="Rhode Island" sx={{ textAlign: 'left' }}>Rhode Island</MenuItem>
                        <MenuItem value="South Carolina" sx={{ textAlign: 'left' }}>South Carolina</MenuItem>
                        <MenuItem value="South Dakota" sx={{ textAlign: 'left' }}>South Dakota</MenuItem>
                        <MenuItem value="Tennessee" sx={{ textAlign: 'left' }}>Tennessee</MenuItem>
                        <MenuItem value="Texas" sx={{ textAlign: 'left' }}>Texas</MenuItem>
                        <MenuItem value="Utah" sx={{ textAlign: 'left' }}>Utah</MenuItem>
                        <MenuItem value="Vermont" sx={{ textAlign: 'left' }}>Vermont</MenuItem>
                        <MenuItem value="Virginia" sx={{ textAlign: 'left' }}>Virginia</MenuItem>
                        <MenuItem value="Washington" sx={{ textAlign: 'left' }}>Washington</MenuItem>
                        <MenuItem value="West Virginia" sx={{ textAlign: 'left' }}>West Virginia</MenuItem>
                        <MenuItem value="Wisconsin" sx={{ textAlign: 'left' }}>Wisconsin</MenuItem>
                        <MenuItem value="Wyoming" sx={{ textAlign: 'left' }}>Wyoming</MenuItem>
                    </Select>
                    {isStateError && <FormHelperText style={{ color: '#c62828' }}>{stateHelperText}</FormHelperText>}
                    </FormControl>
                </div>
                {/* Payer Input */}
                <div style={{ margin: '5px auto' }}>
                    <FormControl required sx={{ marginTop: '10px', width: '100%', textAlign: 'left' }} >
                    <InputLabel style={{ marginTop: '0px', color: isStateError ? '#c62828' : '666666' }}> Payer </InputLabel>
                    <Select required variant="outlined" name="payer" value={payer}
                        onChange={handlePayerChange} error={isPayerError} 
                        inputProps={{ style: { textAlign: 'left' } }} fullWidth >
                        <MenuItem value="Medicare" sx={{ textAlign: 'left' }}>Medicare</MenuItem>
                        <MenuItem value="Aetna" sx={{ textAlign: 'left' }}>Aetna</MenuItem>
                        <MenuItem value="BCBS" sx={{ textAlign: 'left' }}>BCBS</MenuItem>
                        <MenuItem value="Cigna" sx={{ textAlign: 'left' }}>Cigna</MenuItem>
                    </Select>
                    {isStateError && <FormHelperText style={{ color: '#c62828' }}>{payerHelperText}</FormHelperText>}
                    </FormControl>
                </div>
                {/* Number of Patients Input */}
                <div style={{ margin: '5px auto' }}>
                    <TextField required label="Number of Patients" name="numPatients" type="number" value={numPatients} 
                    onChange={handleNumPatientsChange} error={isNumPatientsError} margin="normal"
                    helperText={numPatientsHelperText} style={{ marginTop: '10px' }} fullWidth />
                </div>
                {/* CCM Codes Input */}
                <div style={{ display: 'flex', justifyContent: 'flex-start', margin: '5px auto' }}>
                    <FormControl required error={isCodesError} component="fieldset" variant="standard"
                        sx={{ display: 'flex', justifyContent: 'flex-start'}} >
                    <FormLabel component="legend" sx={{ display: 'flex', justifyContent: 'flex-start', color: 'black', marginTop: '20px' }}>
                        Select which Chronic Care Management codes your practice will utilize.
                    </FormLabel>                    
                    <RadioGroup aria-label="options" name="" value={code} onChange={handleCodeChange} >
                        <FormControlLabel value='99487' control={<Radio />} 
                            label={<span style={{ color: 'black' }}>CPT 99487, 60 minutes per month</span>} />
                        <FormControlLabel value='99490' control={<Radio />} 
                            label={<span style={{ color: 'black' }}>CPT 99490, 20 minutes per month</span>} />
                    </RadioGroup>
                    {isCodesError && <FormHelperText>{codesHelperText}</FormHelperText>}
                    </FormControl>
                </div>
                <div style={{ margin: '10px auto', width: '20rem', marginBottom: '10px', }}>
                    <Button variant="contained" color="primary" margin="normal" onClick={handleSubmit}> Calculate Revenue </Button>
                </div>
                {revenue !== null && (
                    <Box sx={{ height: 100, backgroundColor: 'white', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                        textAlign: 'center', lineHeight: '300px', fontSize: '24px', borderRadius: '4px', 
                        display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                        <Typography variant="h6"style={{ color: '#1565c0', marginBottom: '10px' }} marginY={2}> Annual Revenue Value: ${revenue}* </Typography>
                    </Box>
                )}
                <Typography variant="body1"style={{ color: '#1565c0', marginBottom: '10px', fontSize: '10px' }} marginY={2}> *Flair and the partner clinic will share the collected revenue at an agreed-upon revenue share percentage. </Typography>
            </div>
        </div>
    )
}

export default function RevenueCalculator() {
    // return (
    //     <div style={{ display: 'flex' }}>
    //     <Navbar />
    //     {/* <Sidebar /> */}
    //     <div className="App">
    //       <Container sx = {{ paddingBottom: '2%'}}>
    //         <ThemeProvider theme={theme}>
    //             <RevenueCalcForm />
    //         </ThemeProvider>
    //       </Container>
    //     </div>
    //   </div>
    // )
    return (
        <ThemeProvider theme={theme}>
          <Grid container>
            <Grid item xs={12}>
              <Navbar />
            </Grid>
            <Grid item xs={12}>
              <Container sx={{ paddingBottom: '2%' }}>
                <div className="App">
                  <RevenueCalcForm />
                </div>
              </Container>
            </Grid>
          </Grid>
        </ThemeProvider>
      );
}