import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControl, FormControlLabel, FormHelperText, FormLabel, FormGroup } from '@mui/material';
import { InputLabel, Select, MenuItem, Container, Checkbox, Button, ThemeProvider, TextField, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import { theme } from '../Colors';
import axios from 'axios';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

// const baseUrl = 'http://localhost:4000';
const baseUrl = 'https://flairhealthbackend.onrender.com';

function PatientOnboardForm() {
    const navigate = useNavigate();

    const [newPatient, setNewPatient] = useState({ 
        firstName: '',
        lastName: '',
        birthDate: null,
        sex: '',
        state: '',
        email: '',
        phone: '',
        insuranceProvider: '',
        insuranceGroup: '',
        insuranceId: '',
        consent: false, 
        clinicName: '',
        dieticianPathway: false, 
        medAdherencePathway: false, 
        patientEducationPathway: false, 
        referralNotes: '',
        ehrAuth: false
    });

    const [isFirstNameError, setFirstNameError] = useState(false);
    const [isLastNameError, setLastNameError] = useState(false);
    const [isBirthDateError, setBirthDateError] = useState(false);
    const [isSexError, setSexError] = useState(false);
    const [isStateError, setStateError] = useState(false);
    const [isEmailError, setIsEmailError] = useState(false);
    const [isPhoneError, setIsPhoneError] = useState(false);
    const [isInsuranceError, setInsuranceError] = useState(false);
    const [isClinicNameError, setClinicNameError] = useState(false);
    const [isPathwayError, setPathwayError] = useState(false);
    const [isReferralNotesError, setReferralNotesError] = useState(false);
    const [isConsentAndAuthError, setConsentAndAuthError] = useState(false);

    const [firstNameHelperText, setFirstNameHelperText] = useState("");
    const [lastNameHelperText, setLastNameHelperText] = useState("");
    const [birthDateHelperText, setBirthDateHelperText] = useState("");
    const [sexHelperText, setSexHelperText] = useState("");
    const [stateHelperText, setStateHelperText] = useState("");
    const [emailHelperText, setEmailHelperText] = useState("");
    const [phoneHelperText, setPhoneHelperText] = useState("");
    const [insuranceHelperText, setInsuranceHelperText] = useState("");
    const [clinicNameHelperText, setClinicNameHelperText] = useState("");
    const [pathwayHelperText, setPathwayHelperText] = useState("");
    const [referralNotesHelperText, setReferralNotesHelperText]  = useState("");
    const [consentAndAuthHelperText, setConsentAndAuthHelperText] = useState(false);

    const handleSignUp = async () => {
        try {
            var valid = true;
            if (newPatient.firstName === null || newPatient.firstName === '') {
                setFirstNameError(true);
                setFirstNameHelperText('Please enter a valid first name.');
                valid = false;
            }
            if (newPatient.lastName === null || newPatient.lastName === '') {
                setLastNameError(true);
                setLastNameHelperText('Please enter a valid last name.');
                valid = false;
            }
            if (newPatient.birthDate === null || newPatient.birthDate === '') {
                setBirthDateError(true);
                setBirthDateHelperText('Please enter a valid birthdate.');
                valid = false;
            }
            if (newPatient.sex === null || newPatient.sex === '') {
                setSexError(true);
                setSexHelperText('Please select patient\'s sex.');
                valid = false;
            }
            if (newPatient.state === null || newPatient.state === '') {
                setStateError(true);
                setStateHelperText('Please enter patient\'s state.');
                valid = false;
            }
            if (newPatient.email === null || newPatient.email === '') {
                setIsEmailError(true);
                setEmailHelperText('Please enter a valid email.');
                valid = false;
            }
            if (newPatient.phone === null || newPatient.phone === '') {
                setIsPhoneError(true);
                setPhoneHelperText('Please enter a valid phone number.');
                valid = false;
            }
            if (newPatient.insuranceProvider === null || newPatient.insuranceProvider === '' ||
                newPatient.insuranceGroup === null || newPatient.insuranceGroup === '' ||
                newPatient.insuranceId === null || newPatient.insuranceId === '') {
                setInsuranceError(true);
                setInsuranceHelperText('Please enter valid insurance information.');
                valid = false;
            }
            if (newPatient.clinicName === null || newPatient.clinicName === '') {
                setClinicNameError(true);
                setClinicNameHelperText('Please enter a valid clinic name.');
                valid = false;
            }
            if (!(newPatient.dieticianPathway || newPatient.medAdherencePathway || newPatient.patientEducationPathway)) {
                setPathwayError(true);
                setPathwayHelperText('Please select at least one pathway to enroll the patient.');
                valid = false;
            }
            if (newPatient.referralNotes === null || newPatient.referralNotes === '') {
                setReferralNotesError(true);
                setReferralNotesHelperText('Please enter a valid clinic name.');
                valid = false;
            }
            if (!(newPatient.consent || newPatient.ehrAuth)) {
                setConsentAndAuthError(true);
                setConsentAndAuthHelperText('Please get the patient\'s consent to enroll in the program.');
                valid = false;
            }
            if (!valid) return;
            const response = await axios.post(`${baseUrl}/patients/add`, newPatient);
            console.log(response.data);
            navigate(`patients`); // navigate(`/start`, { state: { clinicObj: response.data } });
        } catch (err) {
          console.error('Error:', err);
        }
    };

    const handleTextChange = (event) => {
        const { name, value } = event.target;
        setNewPatient((prevPatient) => ({
          ...prevPatient,
          [name]: value,
        }));
    };

    const handleDateChange = (date) => {
        setNewPatient((prevPatient) => ({
          ...prevPatient,
          birthDate: date,
        }));
    };

    const handleBoolChange = (event) => {
        const { name, checked } = event.target;
        setNewPatient((prevPatient) => ({
            ...prevPatient,
            [name]: checked,
        }));
    };

    return (
        <div>
            <Typography variant="h5" style={{ color: '#1565c0', marginBottom: '10px' }}>Onboard New Patient</Typography>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <TextField required label="First Name" name="firstName" value={newPatient.firstName} onChange={handleTextChange}
                error={isFirstNameError} helperText={firstNameHelperText} style={{ marginTop: '10px' }} fullWidth />
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <TextField required label="Last Name" name="lastName" value={newPatient.lastName} onChange={handleTextChange}
                error={isLastNameError} helperText={lastNameHelperText} style={{ marginTop: '10px' }} fullWidth />
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker disableFuture label="Birth Date" name="birthDate" value={newPatient.birthDate} onAccept={handleDateChange}
                slotProps={{ textField: { variant: 'outlined', error: !!isBirthDateError, helperText: birthDateHelperText } }}
                sx={{ marginTop: '10px', width: '100%' }} onError={(err) => setBirthDateError(err)} />
            </LocalizationProvider>
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <FormControl sx={{ marginTop: '10px', width: '100%' }} >
            <InputLabel style={{ marginTop: '0px', color: isSexError ? '#c62828' : '666666' }}> Sex </InputLabel>
            <Select required variant="outlined" name="sex" value={newPatient.sex} onChange={handleTextChange}
                error={isSexError} fullWidth >
                <MenuItem value={"M"}>M</MenuItem>
                <MenuItem value={"F"}>F</MenuItem>
            </Select>
            {isSexError && <FormHelperText style={{ color: '#c62828' }}>{sexHelperText}</FormHelperText>}
            </FormControl>
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <TextField required label="State" name="state" value={newPatient.state} onChange={handleTextChange}
                error={isStateError} helperText={stateHelperText} style={{ marginTop: '10px' }} fullWidth />
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <TextField required label="Email" name="email" value={newPatient.email} onChange={handleTextChange}
                error={isEmailError} helperText={emailHelperText} style={{ marginTop: '10px' }} fullWidth />
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <TextField required label="Phone Number" name="phone" value={newPatient.phone} onChange={handleTextChange}
                error={isPhoneError} helperText={phoneHelperText} style={{ marginTop: '10px' }} fullWidth />
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <TextField required label="Clinic Name" name="clinicName" value={newPatient.clinicName} onChange={handleTextChange}
                error={isClinicNameError} helperText={clinicNameHelperText} style={{ marginTop: '10px' }} fullWidth />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start', margin: '5px auto', width: '50rem' }}>
                <FormControl required error={isPathwayError} component="fieldset" variant="standard"
                sx={{ display: 'flex', justifyContent: 'flex-start'}} >
                <FormLabel component="legend" sx={{ display: 'flex', justifyContent: 'flex-start', color: 'black', marginTop: '20px' }}>
                    Select the program pathways to enroll this patient.
                </FormLabel>
                <FormGroup>
                    <FormControlLabel control={<Checkbox name="dieticianPathway"
                        checked={newPatient.dieticianPathway} onChange={handleBoolChange} />}
                        label={<span style={{ color: 'black' }}>Dietician and Nutritionist Counseling</span>} />
                    <FormControlLabel control={<Checkbox name="medAdherencePathway"
                        checked={newPatient.medAdherencePathway} onChange={handleBoolChange} />}
                        label={<span style={{ color: 'black' }}>Medication Adherence</span>} />
                    <FormControlLabel control={<Checkbox name="patientEducationPathway" 
                        checked={newPatient.patientEducationPathway} onChange={handleBoolChange} />} 
                        label={<span style={{ color: 'black' }}>Health Coaching and Education</span>} />
                </FormGroup>
                {isPathwayError && <FormHelperText>{pathwayHelperText}</FormHelperText>}
                </FormControl>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start', margin: '20px auto', width: '50rem' }}>
                <FormControl required error={isInsuranceError} component="fieldset" variant="standard"
                sx={{ display: 'flex', justifyContent: 'flex-start'}} >
                <FormLabel component="legend" sx={{ display: 'flex', justifyContent: 'flex-start', color: 'black' }}>
                    Complete the patient's insurance information.
                </FormLabel>
                <FormGroup>
                    <TextField required label="Insurance Provider" name="insuranceProvider" value={newPatient.insuranceProvider} 
                        onChange={handleTextChange} style={{ marginTop: '5px' }} fullWidth />
                    <TextField required label="Group Number" name="insuranceGroup" value={newPatient.insuranceGroup} 
                        onChange={handleTextChange} style={{ marginTop: '5px' }} fullWidth />
                    <TextField required label="Member ID" name="insuranceId" value={newPatient.insuranceId} 
                        onChange={handleTextChange} style={{ marginTop: '5px' }} fullWidth />
                </FormGroup>
                {isInsuranceError && <FormHelperText>{insuranceHelperText}</FormHelperText>}
                </FormControl>
            </div>
            <div style={{ margin: '5px auto', width: '50rem' }}>
            <TextField required label="Patient Conditions and Goals" name="referralNotes" value={newPatient.referralNotes} onChange={handleTextChange}
                error={isReferralNotesError} helperText={referralNotesHelperText} style={{ marginTop: '10px' }} multiline rows={4} fullWidth />
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start', margin: '5px auto', width: '50rem' }}>
                <FormControl required error={isConsentAndAuthError} component="fieldset" variant="standard"
                sx={{ display: 'flex', justifyContent: 'flex-start'}} >
                <FormGroup>
                <FormControlLabel required control={<Checkbox name="consent" size="large" checked={newPatient.consent} onChange={handleBoolChange} />}
                    label={<span style={{ color: 'black' }}>Check this box to confirm the patient has consented to join the Flair program.</span>} />
                <FormControlLabel required control={<Checkbox name="ehrAuth" size="large" checked={newPatient.ehrAuth} onChange={handleBoolChange} />}
                    label={<span style={{ color: 'black' }}>Check this box to authorize Flair's clinical team to access the patient's medical records.</span>} />
                </FormGroup>
                {isConsentAndAuthError && <FormHelperText>{consentAndAuthHelperText}</FormHelperText>}
                </FormControl>
            </div>

            <div style={{ margin: '10px auto', width: '20rem' }}>
                <Button variant={'contained'} onClick={handleSignUp}>Sign Up</Button>
            </div>
        </div>
    )
}

export default function OnboardPatient() {
    return (
        <div style={{ display: 'flex' }}>
        <Navbar />
        <Sidebar />
        <div className="App">
          <Container sx = {{ paddingBottom: '2%'}}>
            <ThemeProvider theme={theme}>
                <PatientOnboardForm />
            </ThemeProvider>
          </Container>
        </div>
      </div>
    )
}