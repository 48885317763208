import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

import RevenueCalculator from './components/RevenueCalculator';
import OnboardPatient from './components/PatientOnboarding';
import StaffList from './components/StaffList';
import PatientList from './components/PatientList';
import BillingReport from './components/BillingReport';
import PatientInfoDisplay from './components/PatientInfoDisplay';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<RevenueCalculator />} />
          <Route path="/onboard" element={<OnboardPatient />} />
          <Route path="/staff" element={<StaffList />} />
          <Route path="/patients" element={<PatientList />} />
          <Route path="/billing" element={<BillingReport />} />
          <Route path="/patient/:id" element={<PatientInfoDisplay />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
