import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, IconButton, Container, Typography, TextField, Paper, ThemeProvider } from '@mui/material';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, TablePagination } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Input } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { colors, theme } from '../Colors';
import axios from 'axios';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const StyledTableCell = styled(TableCell)(() => ({
    backgroundColor: colors.tableHeadBackground,
    color: colors.tableHeadText
}));

export default function BillingReport() {
    // const baseUrl = 'http://localhost:4000';
    const baseUrl = 'https://flairhealthbackend.onrender.com';
    const navigate = useNavigate();

    // const { state } = useLocation();
    // const clinicData = state?.clinicObj || {};
    const clinicData = {id: '64e93d4a47c3cfc7931b5362'};

    const [documents, setDocuments] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [openDocumentDialog, setOpenDocumentDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [documentToDelete, setDocumentToDelete] = useState(null);

    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    useEffect(() => {
        axios.get(`${baseUrl}/clinics/documents/${clinicData.id}`)
        .then(response => {
            const sortedDocuments = response.data.sort((a, b) => a.fileName.localeCompare(b.fileName));
            setDocuments(sortedDocuments);
        })
        .catch(err => {
            console.log("Error: " + err);
        });
    }, []);

    const handleSearch = event => {
        setSearchQuery(event.target.value);
    };

    const filteredDocuments = documents.filter(document =>
        document.fileName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        document.fileName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const pageDocuments = filteredDocuments.slice(startIndex, endIndex);

    const handleOpenDocumentDialog = () => {
      setOpenDocumentDialog(true);
    };
  
    const handleCloseDocumentDialog = () => {
      setOpenDocumentDialog(false);
    };

    const handleOpenDeleteDialog = (documentId) => {
      setOpenDeleteDialog(true);
      setDocumentToDelete(documentId);
    };
  
    const handleCloseDeleteDialog = () => {
      setOpenDeleteDialog(false);
      setDocumentToDelete(null);
    };
    
    const handleFileSelect = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
    };

    const handleNewFileUpload = async () => {
      if (!selectedFile) {
        return;
      }
  
      try {
        const formData = new FormData();
        formData.append('file', selectedFile);
        const response = await axios.post(`${baseUrl}/clinics/upload/${clinicData.id}`, formData);
        console.log('Document added successfully:', response.data);
        handleCloseDocumentDialog();
        const updatedDocuments = await axios.get(`${baseUrl}/clinics/documents/${clinicData.id}`);
        setDocuments(updatedDocuments.data);
        setSelectedFile(null);
      } catch (error) {
        console.error('Error:', error);
      }
    };
  
    const addDocumentForm = () => {
      return (
        <div>
          <Input type="file" onChange={handleFileSelect} />
        </div>
      );
    };
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDeleteFile = async() => {
      if (!documentToDelete) {
        return;
      }
      try {
        const response = await axios.delete(`${baseUrl}/clinics/documents/${clinicData.id}/${documentToDelete}`);
        console.log('Document deleted successfully:', response.data);
        const updatedDocuments = await axios.get(`${baseUrl}/clinics/documents/${clinicData.id}`);
        setDocuments(updatedDocuments.data);
        setOpenDeleteDialog(false);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    return (
      <div style={{ display: 'flex' }}>
      <Navbar />
      <Sidebar />
        <div className="App">
          <Container sx = {{ paddingBottom: '2%'}}>
              <Typography variant="h5" style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}> Billing </Typography>
              <TextField label="Search Documents" value={searchQuery} fullWidth style={{ paddingBottom: '10px' }} onChange={handleSearch} />
              <ThemeProvider theme={theme}>
                  <Button variant='contained' sx={{ marginLeft: '82%' }} onClick={handleOpenDocumentDialog}>Upload Document</Button>
                  <Dialog open={openDocumentDialog} onClose={handleCloseDocumentDialog}>
                    <DialogTitle>
                      <Typography variant="h5" style={{ marginTop: '10px' }} color={colors.tableHeadText}> Upload Document </Typography>
                    </DialogTitle>
                    <DialogContent> {addDocumentForm()} </DialogContent>
                    <DialogActions sx={{ marginBottom: '10px' }}>
                      <Button onClick={handleCloseDocumentDialog} color="primary">Cancel</Button>
                      <Button onClick={handleNewFileUpload} color="primary">Add File</Button>
                    </DialogActions>
                  </Dialog>
              </ThemeProvider>
          </Container>

          <ThemeProvider theme={theme}> 
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
            <DialogTitle><Typography variant="h5" style={{ marginTop: '10px' }} color={colors.tableHeadText}> Delete Document </Typography></DialogTitle>
            <DialogContent>
              <Typography variant="body1" style={{ marginTop: '10px' }}> Are you sure you want to delete this document?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
              <Button onClick={handleDeleteFile} color="primary">Confirm</Button>
            </DialogActions>
            </Dialog>
          </ThemeProvider>

          <TableContainer component={Paper} sx={{ width: '80%' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell><Typography variant="body1">File Name</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Type</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Open</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Delete</Typography></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageDocuments.map(document => (
                  <TableRow key={document._id}>
                    <TableCell>{document.fileName}</TableCell>
                    <TableCell>{document.contentType}</TableCell>
                    <TableCell><IconButton onClick={() => handleOpenDeleteDialog(document._id)}><Delete/></IconButton></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination component="div" count={documents.length}
              rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]} 
              rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}
              page={page} onPageChange={handleChangePage} />
          </TableContainer>


          <Dialog open={openDocumentDialog} onClose={handleCloseDocumentDialog}>
                    <DialogTitle>
                      <Typography variant="h5" style={{ marginTop: '10px' }} color={colors.tableHeadText}> Upload Document </Typography>
                    </DialogTitle>
                    <DialogContent> {addDocumentForm()} </DialogContent>
                    <DialogActions sx={{ marginBottom: '10px' }}>
                      <Button onClick={handleCloseDocumentDialog} color="primary">Cancel</Button>
                      <Button onClick={handleNewFileUpload} color="primary">Add File</Button>
                    </DialogActions>
                  </Dialog>

          <Container sx = {{ paddingBottom: '3%' }}> </Container>
        </div>
      </div>
    )
}