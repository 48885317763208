import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Paper } from '@mui/material';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import MailIcon from '@mui/icons-material/Mail';
import { styled } from '@mui/material/styles';
import { colors } from '../Colors';
import dietician from '../images/dietician.png'
import health_coach from '../images/health_coach.png'
import nurse from '../images/nurse.png'
import axios from 'axios';
import Navbar from './Navbar'
import Sidebar from './Sidebar'

const StyledTableCell = styled(TableCell)(() => ({
    backgroundColor: colors.tableHeadBackground,
    color: colors.tableHeadText
}));

export default function StaffList() {
    // const baseUrl = 'http://localhost:4000';
    const baseUrl = 'https://flairhealthbackend.onrender.com';

    // const [newStaff, setNewStaff] = useState([{
    //   firstName: '',
    //   lastName: '',
    //   degree: '',
    //   email: '',
    //   phone: '',
    //   password: '',
    //   statesLicense: [],
    //   insuranceCredentials: [],
    //   bio: '',
    //   headshot: '', 
    //   patients: [],
    //   clinics: []
    // }]);
    const [searchQuery, setSearchQuery] = useState('');
    // const [page] = useState(0);
    // const [rowsPerPage] = useState(10);
    // const startIndex = page * rowsPerPage;
    // const endIndex = startIndex + rowsPerPage;

    // const { state } = useLocation();
    // const clinicData = state?.clinicObj || {};
    // const clinicData = {id: '64e93d4a47c3cfc7931b5362'}; //change to applicable clinic

    // useEffect(() => {
    //     axios.get(`${baseUrl}/flairStaff/`)
    //     .then(response => {
    //         const sortedStaff = response.data.sort((a, b) => a.lastName.localeCompare(b.lastName));
    //         setNewStaff(sortedStaff);
    //     })
    //     .catch(err => {
    //         console.log("Error: " + err);
    //     });
    // }, []);

    const handleSearch = event => {
        setSearchQuery(event.target.value);
    };

    // const filteredStaff = newStaff.filter(staff =>
    //     staff.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     staff.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    // );

    // const pageStaff = filteredStaff.slice(startIndex, endIndex);
    
    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    // const handleChangeRowsPerPage = (event) => {
    //     setRowsPerPage(parseInt(event.target.value, 10));
    //     setPage(0);
    // };

    return (
      <div style={{ display: 'flex' }}>
      <Navbar />
      <Sidebar />
        <div className="App">
          <Container sx = {{ paddingBottom: '2%'}}>
              <Typography variant="h5" style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}> Flair Staff </Typography>
              <TextField label="Search Staff" value={searchQuery} fullWidth style={{ paddingBottom: '10px' }} onChange={handleSearch} />
          </Container>

          <TableContainer component={Paper} sx={{ width: '80%' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                <StyledTableCell><Typography variant="body1"> </Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">First Name</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Last Name</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Role</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Bio</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1"></Typography></StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow key="dietician">
                    <TableCell>
                      <img src={dietician} 
                        alt="Emily Thompson"
                        width="100" 
                        height="100" />
                    </TableCell>
                    <TableCell>Emily</TableCell>
                    <TableCell>Thompson</TableCell>
                    <TableCell>Registered Dietitian</TableCell>
                    <TableCell> Dr. Emily Thompson, an esteemed Registered Dietitian and Certified Diabetes Educator, specializes 
                      in diabetes and endocrine nutrition, providing individuals with personalized dietary strategies for optimal 
                      blood sugar control and endocrine health. Merging the latest science with a compassionate approach, Emily not 
                      only aids in glycemic management but also engages in community programs and workshops, striving to bolster 
                      well-being and knowledge for those managing diabetes and endocrine issues. </TableCell>
                    <TableCell><MailIcon style={{ color: colors.blue }} /></TableCell>
                  </TableRow>
                  <TableRow key="coach">
                    <TableCell>
                      <img src={health_coach} 
                        alt="Michael Johnson"
                        width="100" 
                        height="100" />
                    </TableCell>
                    <TableCell>Michael</TableCell>
                    <TableCell>Johnson</TableCell>
                    {/* <TableCell>Health Coach</TableCell>
                    <TableCell> Michael Johnson, a certified health coach, is passionate about helping clients establish and maintain
                      healthy lifestyles. Leveraging his expertise in wellness coaching, Michael assists individuals in navigating through
                      their health journeys by providing them with the necessary tools, strategies, and motivation. </TableCell>
                    <TableCell><MailIcon style={{ color: colors.primary }} /></TableCell>     
                  </TableRow>
                  <TableRow key="navigator">
                    <TableCell>
                      <img src={nurse} 
                        alt="Laura Martinez"
                        width="100" 
                        height="100"
                      />
                    </TableCell>
                    <TableCell>Laura</TableCell>
                    <TableCell>Martinez</TableCell> */}
                    <TableCell>Care Navigator</TableCell>
                    <TableCell> Michael Johnson, a certified medical assistant, is passionate about helping clients establish and maintain
                      healthy lifestyles. Leveraging his expertise in wellness coaching, Michael assists individuals in navigating through
                      their health journeys by providing them with the necessary tools, strategies, and motivation. </TableCell>
                    <TableCell><MailIcon style={{ color: colors.primary }} /></TableCell>
                    {/* <TableCell> Laura Martinez, an experienced registered nurse and care navigator, is dedicated to ensuring that 
                      patients receive coordinated and comprehensive care. She plays a pivotal role in guiding patients through the 
                      healthcare system, ensuring they have seamless access to the resources and services needed to manage their health 
                      effective systems.
                    </TableCell> */}
                    {/* <TableCell><MailIcon style={{ color: colors.blue }} /></TableCell>      */}
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Container sx = {{ paddingBottom: '3%' }}> </Container>
        </div>
      </div>
    )
}