import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container, Typography, TextField, Paper, ThemeProvider } from '@mui/material';
import { TableContainer, Table, TableHead, TableBody, TableCell, TableRow, TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors, theme } from '../Colors';
import axios from 'axios';
import Navbar from './Navbar'
import Sidebar from './Sidebar'

const StyledTableCell = styled(TableCell)(() => ({
    backgroundColor: colors.tableHeadBackground,
    color: colors.tableHeadText
}));

export default function PatientList() {
    // const baseUrl = 'http://localhost:4000';
    const baseUrl = 'https://flairhealthbackend.onrender.com';
    const navigate = useNavigate();

    // const { state } = useLocation();
    // const clinicData = state?.clinicObj || {};
    const clinicData = {id: '64e93d4a47c3cfc7931b5362'}; //change to applicable clinic

    const [patients, setPatients] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;

    useEffect(() => {
        axios.get(`${baseUrl}/patients/`)
        .then(response => {
            const sortedPatients = response.data.sort((a, b) => a.lastName.localeCompare(b.lastName));
            setPatients(sortedPatients);
        })
        .catch(err => {
            console.log("Error: " + err);
        });
    }, []);

    const handleSearch = event => {
        setSearchQuery(event.target.value);
    };

    const filteredPatients = patients.filter(patient =>
        patient.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        patient.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const pagePatients = filteredPatients.slice(startIndex, endIndex);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const DisplayProgramPathways = (patient) => {
      const selectedPathways = [];
      if (patient.intakeInfo.dieticianPathway === true) {
        selectedPathways.push("Dietician and Nutrition Counseling");
      }
      if (patient.intakeInfo.medAdherencePathway === true) {
        selectedPathways.push("Medication Adherence");
      }
      if (patient.intakeInfo.patientEducationPathway === true) {
        selectedPathways.push("Health Education and Coaching");
      }
      return selectedPathways.join(', ');
    };

    return (
      <div style={{ display: 'flex' }}>
      <Navbar />
      <Sidebar />
        <div className="App">
          <Container sx = {{ paddingBottom: '2%'}}>
              <Typography variant="h5" style={{ textAlign: 'left', paddingBottom: '10px' }} color={colors.tableHeadText}> Patients </Typography>
              <TextField label="Search Patients" value={searchQuery} fullWidth style={{ paddingBottom: '10px' }} onChange={handleSearch} />
          </Container>

          <TableContainer component={Paper} sx={{ width: '80%' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <StyledTableCell><Typography variant="body1">First Name</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Last Name</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Birth Date</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Insurance</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Pathways</Typography></StyledTableCell>
                  <StyledTableCell><Typography variant="body1">Profile</Typography></StyledTableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {pagePatients.map(patient => (
                  <TableRow key={patient._id}>
                    <TableCell>{patient.firstName}</TableCell>
                    <TableCell>{patient.lastName}</TableCell>
                    <TableCell>{new Date(patient.birthDate).toLocaleDateString()}</TableCell>
                    <TableCell>{patient.insuranceProvider}</TableCell>
                    <TableCell>{DisplayProgramPathways(patient)}</TableCell>
                    <TableCell><Button onClick={() => navigate(`/patient/${patient._id}` )}>Open</Button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination component="div" count={patients.length}
              rowsPerPageOptions={[5, 10, 25, { value: -1, label: 'All' }]} 
              rowsPerPage={rowsPerPage} onRowsPerPageChange={handleChangeRowsPerPage}
              page={page} onPageChange={handleChangePage} />
          </TableContainer>

          <Container sx = {{ paddingBottom: '3%' }}> </Container>
        </div>
      </div>
    )
}